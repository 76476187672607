<template>
  <base-section id="join-us">
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="submit"
    >
      <v-container class="fill-height">
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          max-width="1200"
          width="100%"
        >
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="4"
            >
              <span
                class="text-capitalize title_heading"
                size=""
                weight="600"
              >
                {{ $t('title_our_joinUs') }}
              </span>
              <base-subtitle
                :title="$t('description_our_joinUs')"
                mobile-size="h1"
                size="h1"
                class="text-capitalize title_join"
                weight="700"
              />
            </v-col>
            <v-col
              cols="12"
              md="12"
              sm="12"
              lg="8"
            >
              <v-container v-if="isLoading">
                <v-row>
                  <v-col
                    v-for="n in 2"
                    :key="n"
                    cols="12"
                    md="6"
                  >
                    <v-skeleton-loader
                      class="mx-auto"
                      max-width="300"
                      type="card"
                    />
                  </v-col>
                </v-row>
              </v-container>
              <v-container v-if="!isLoading">
                <v-row>
                  <v-col
                    v-for="(vacancy, i) in vacancys"
                    :key="i"
                    cols="12"
                    md="6"
                  >
                    <v-card
                      class="px-9 py-9 bk_card_join mb-3"
                    >
                      <v-btn
                        width="35%"
                        min-height="37"
                        class="btn_join text-capitalize"
                      >
                        {{ vacancy.time_type }}
                      </v-btn>
                      <div class="my-7">
                        <span class="title_card_join">
                          {{ vacancy.title }}
                        </span>
                      </div>
                      <div>
                        <p
                          class="description_card_join"
                          style="white-space: pre-line;line-height: unset !important;"
                        >
                          {{ vacancy.description }}
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
          <div class="my-10">
            <v-container>
              <div class="d-flex justify-start">
                <base-section-heading
                  :title="$t('contactUs')"
                  style="width: max-content"
                  :align="$vuetify.breakpoint.width >= 768 ? 'left' : 'center'"
                />
              </div>
            </v-container>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-select
                    v-model="form.vacancySelected"
                    background-color="#101010"
                    outlined
                    :label="$t('vacancy')"
                    item-text="title"
                    item-value="id"
                    :items="vacancys"
                    filled
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-row>
                <v-col
                  cols="6"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="form.email"
                    background-color="#101010"
                    outlined
                    type="email"
                    counter
                    maxlength="128"
                    :error-messages="errors.email"
                    :rules="[rules.required('email')]"
                    name="email"
                    placeholder="debra.holt@example.com"
                    filled
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="form.contact"
                    background-color="#101010"
                    outlined
                    counter
                    maxlength="128"
                    :placeholder="$t('additional_contacts_placeholder')"
                    filled
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-textarea
                v-model="form.description"
                type="text"
                name="description"
                counter
                maxlength="10000"
                :error-messages="errors.description"
                :rules="[rules.required('description')]"
                outlined
                background-color="#101010"
                :placeholder="$t('request_description')"
                filled
              />
            </v-container>
            <v-container>
              <div
                class="add_file_contact"
                :style="{ 'background-color': color }"
                @dragleave="fileDragOut"
                @dragover="fileDragIn"
                @drop="handleFileInput"
              >
                <input
                  ref="file"
                  accept=".pdf,.jpg,.jpeg,.png"
                  type="file"
                  name="file-input"
                  multiple
                  class="input_file"
                  @change="onChangeFileAttached"
                >
                <div
                  class="d-flex justify-center mx-auto"
                  style="margin-top: 5%"
                >
                  <div class="float-left">
                    <v-img
                      class="mx-4"
                      height="25px"
                      width="15px"
                      src="@/assets/skrepka.png"
                    />
                  </div>
                  <div>
                    <span
                      style="font-size: 21px; font-weight:400; text-transform: none;"
                    >
                      {{ $t('attach_file') }}
                    </span>
                  </div>
                </div>
                <div class="d-flex justify-center mx-auto">
                  <span
                    style="font-size: 14px; font-weight:400; text-transform: none;"
                  >
                    {{ $t('attach_file_description') }}
                  </span>
                </div>
                <div style="text-align: center">
                  <span
                    v-for="(file, index) in files"
                    :key="index"
                  >
                    {{ file.name }} ({{ file.size }} b)
                  </span>
                </div>
              </div>
            </v-container>
            <v-container class="fill-height py-0">
              <v-row>
                <v-col
                  lg="2"
                  sm="6"
                  md="6"
                >
                  <v-btn
                    width="100%"
                    min-height="60"
                    :loading="loading"
                    :disabled="loading || !valid"
                    class="btn_more mt-lg-12"
                    type="submit"
                  >
                    {{ $t('send') }}
                  </v-btn>
                </v-col>
                <v-col
                  lg="2"
                  sm="6"
                  md="6"
                >
                  <v-btn
                    width="100%"
                    min-height="60"
                    class="btn_more mt-lg-12"
                    @click="remove()"
                  >
                    {{ $t('cancel') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-responsive>
      </v-container>
    </v-form>
    <v-dialog
      v-model="successVisible"
      persistent
      max-width="466"
    >
      <v-card
        color="white"
        style="padding-bottom: 10%;padding-top: 10%;"
      >
        <div
          class="d-flex align-center justify-center"
        >
          <img
            class="d-flex align-center justify-center"
            :src="require('@/assets/img_success.svg')"
            alt=""
          >
        </div>
        <div
          class="d-flex align-center justify-center"
        >
          <span class="text_success">
            Success
          </span>
        </div>
        <div
          class="d-flex align-center justify-center"
        >
          <span class="text_content">
            Check your email for the offer confirmation.
          </span>
        </div>
        <div
          class="d-flex align-center justify-center"
        >
          <v-card-actions class="align-center">
            <v-btn
              class="btn_success"
              @click="closeSuccess"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </base-section>
</template>

<script>
  import { mapGetters } from 'vuex'
  import BaseSubtitle from '../../components/base/Subtitle'
  import Form from '../../mixins/form'
  import axios from 'axios'
  import { api } from '../../config'

  export default {
    name: 'SectionJoinUs',
    components: { BaseSubtitle },
    mixins: [Form],
    data () {
      return {
        files: [],
        color: 'black',
        loading: false,
        successVisible: false,
        form: {
          vacancySelected: '',
          email: null,
          contact: null,
          description: null,
        },
      }
    },
    computed: mapGetters({
      isLoading: 'vacancy/isLoading',
      vacancys: 'vacancy/vacancys',
    }),
    mounted () {
      this.$store.dispatch('vacancy/fetchVacancys')
    },
    methods: {
      onChangeFileAttached () {
        this.files = [...this.$refs.file.files]
        console.log('onChangeFileAttached: ' + JSON.stringify(this.files))
        this.files.forEach(element => console.log(JSON.stringify(element.name)))
        this.color = '#444444'
      },
      remove () {
        this.files = []
        this.color = 'black'
        this.loading = false
        this.successVisible = false
        this.form.vacancySelected = ''
        this.form.email = ''
        this.form.description = ''
        this.form.contact = ''
        this.$refs.form.reset()
      },
      submit () {
        console.log(this.form.vacancySelected)
        if (this.$refs.form.validate()) {
          this.loading = true

          const formData = new FormData()
          if (this.files.length > 0) formData.append('file', this.files[0])
          formData.append('vacancy_id', this.form.vacancySelected)
          formData.append('email', this.form.email)
          formData.append('additional_contacts', this.form.contact)
          formData.append('text', this.form.description)

          axios.post(api.path('candidates.add'), formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
            .then(res => {
              this.successVisible = true
            })
            .catch(err => {
              console.log(err)
            })
        }
      },
      handleFileInput (event) {
        event.preventDefault()
        this.$refs.file.files = event.dataTransfer.files
        this.onChangeFileAttached()
      },
      fileDragIn () {
        this.color = '#2c2c2c'
      },
      fileDragOut () {
        this.color = '#272727'
      },
      closeSuccess () {
        this.loading = false
        this.files = []
        this.color = 'black'
        this.form.email = ''
        this.form.description = ''
        this.form.contact = ''
        this.form.vacancySelected = ''
        this.successVisible = false
        this.$refs.form.reset()
      },
    },
  }
</script>
<style lang="sass">
  .title_join
    font-weight: 700 !important
    font-size: 40px !important

  .bk_card_join
    background-color: #121212 !important
    font-size: 25px !important

  .title_heading
    color: #0A8C4C !important
    font-size: 25px !important
    font-weight: 600

  .btn_join
    background: #0A8C4C !important

  .title_card_join
    font-style: normal
    font-size: 25px
    font-weight: 700 !important
    text-transform: uppercase

  .description_card_join
    font-size: 12px
    color: #BDBDBD

  .add_file_contact
    border: 2px dashed #828282
    box-sizing: border-box

  .btn_more
    color: white !important
    border: 2px solid #0A8C4C
    background: transparent
    text-align: center
  .file-wrapper
      text-align: center
      width: 300px
      height: 5em
      vertical-align: middle
      display: table-cell
      position: relative
      overflow: hidden
      background: gray
  .add_file_contact
      border: 1px dashed #828282
      box-sizing: border-box
      position: relative
      height: 178px
  .input_file
      font-size: 14px
      font-weight: 400
      text-transform: none
      position: absolute
      height: 100%
      width: 100%
  [v-cloak]
    display: none
  input[type='file']
    opacity: 0
  .text_content
    font-style: normal
    font-weight: normal
    font-size: 18px
    line-height: 22px
    text-align: center
    color: #828282
  .text_success
    font-style: normal
    font-weight: 600
    font-size: 35px
    line-height: 43px
    color: #000000
  .btn_success
    width: 105.8px
    height: 46px
    color: #FFFFFF
    background: #0A8C4C !important
    border: 2px solid #0A8C4C
</style>
